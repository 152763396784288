import { deliveryNoticeService } from '@/api';
import { ApiResponse } from '@/api/axios';
import { Paging } from '@/api/base';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import {
  DeliveryModeEnum,
  DeliveryStatusEnum,
  ImportTemplateEnum,
  LogisticsOrderStatusEnum
} from '@/resource/enum';
import {
  AddLogisticsParams,
  DeliveryNoticeDetail,
  DeliveryShopItemRelList,
  DeliveryShopList,
  DeliveryShopListQuery,
  ImportRes,
  LogisticsList
} from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import { dateFormat, downloadFileByBlob, handleImportError, messageError, translation } from '@/utils';
import { ImportFile } from '@/views/dialogs';
import { Message } from 'element-ui';
import { cloneDeep } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import AddLogistics from '../../logistics/add-logistics/add-logistics.vue';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'DeliveryNoticeDetails',
  components: { AddLogistics, ImportFile }
})
export default class DeliveryNoticeDetails extends Vue {
  public deliveryNotice: DeliveryNoticeDetail | null = null;

  /**
   * 当前选中的门店明细名称
   */
  public currentDeliveryShopName = '';

  /**
   * 当前显示的产品明细列表
   */
  public currentDeliveryProduct: Array<DeliveryShopItemRelList> = [];

  public shopTableOptions: OsTableOption<Partial<DeliveryShopList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public shopOperationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'logistics.add',
      operationType: 'add',
      disabled: true,
      icon: 'el-icon-circle-plus-outline',
      dynamicHidden: this.allowAddLogistics,
      permissionCode: 'delivery:notice:createLogistics',
      handleClick: this.openAddLogisticDialog
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'deliveryDetails.importLogisticsTitle',
      operationType: 'import',
      icon: 'el-icon-upload2',
      dynamicHidden: this.allowAddLogistics,
      permissionCode: 'delivery:notice:importLogisticsOrder',
      handleClick: this.openImportLogisticsDialog
    }
  ];

  public addLogisticsVisible = false;

  public importLogisticsVisible = false;

  public importLogisticsTitle = 'deliveryDetails.importLogisticsTitle';

  public importLogisticsTemplate = ImportTemplateEnum.logistics;

  public selectedShops: Array<DeliveryShopList> = [];

  public addLogisticsParams: AddLogisticsParams | null = null;

  public shopColumnOptions: Array<OsTableColumn<DeliveryShopList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'shopName',
      label: 'deliveryDetails.deliverySite',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'address',
      label: 'deliveryDetails.deliveryAddress',
      minWidth: '200px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return `${(row as DeliveryShopList).province}
        ${(row as DeliveryShopList).city}
        ${(row as DeliveryShopList).district}
        ${(row as DeliveryShopList).address}`;
      }
    },
    {
      prop: 'receiver',
      label: 'deliveryDetails.receiver',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'receiverTel',
      label: 'deliveryDetails.receiverTel',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'logisticsOrderTotal',
      label: 'deliveryDetails.logisticsOrderTotal',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'sendMethod',
      label: 'logistics.deliveryMode',
      showOverflowTooltip: true,
      minWidth: '120px',
      formatter: (row: Object): string => {
        if (!DeliveryModeEnum[(row as DeliveryShopList).sendMethod]) {
          return '--';
        }
        return translation(`deliveryMode.${DeliveryModeEnum[(row as DeliveryShopList).sendMethod]}`);
      }
    },
    {
      prop: 'remark',
      label: 'logistics.deliveryRemark',
      showOverflowTooltip: true,
      minWidth: '200px'
    }
  ];

  public shopQueryItemsOption: Array<OsQueryItemOption<DeliveryShopListQuery>> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'deliveryDetails.shopName',
      option: {
        placeholder: 'deliveryDetails.inputShopName'
      },
      colSpan: 8
    },
    {
      type: 'Select',
      field: 'isCreatedLogisticsOrder',
      label: 'deliveryDetails.isCreatedLogistics',
      option: {
        placeholder: 'common.select',
        clearable: true
      },
      colSpan: 9,
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('electronicExpressWaybill.yes'),
            value: 'yes'
          },
          {
            label: translation('electronicExpressWaybill.no'),
            value: 'no'
          }
        ];
      }
    }
  ];

  public shopQueryForm: Partial<DeliveryShopListQuery> = {};

  public shopPaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public shopTotal = 0;

  public productTableOptions: OsTableOption<Partial<DeliveryShopItemRelList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public productColumnOptions: Array<OsTableColumn<DeliveryShopItemRelList>> = [
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as DeliveryShopItemRelList).finishWidth} × ${
          (row as DeliveryShopItemRelList).finishHeight
        }`;
      }
    },
    {
      prop: 'productCount',
      label: 'projectProduct.count',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'deliveryDetails.sendCount',
      minWidth: '180px'
    },
    {
      prop: 'planDeliveryTime',
      label: 'deliveryDetails.plannedShippingTime',
      minWidth: '180px',
      formatter: (row: Object): string => {
        return dateFormat((row as DeliveryShopItemRelList).planDeliveryTime);
      }
    },
    {
      prop: 'remark',
      label: 'projectProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  public productPaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public productTotal = 0;

  public logisticsTableOptions: OsTableOption<Partial<DeliveryShopItemRelList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    size: 'mini'
  };

  public logisticsColumnOptions: Array<OsTableColumn<LogisticsList>> = [
    {
      prop: 'code',
      label: 'logistics.code',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'childCount',
      label: 'logistics.childCount',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'status',
      label: 'logistics.status',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'sendMethod',
      label: 'logistics.logisticsInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'receiver',
      label: 'logistics.receiverInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'sender',
      label: 'logistics.senderInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'createUserName',
      label: 'common.createUser',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as LogisticsList).createTime);
      }
    }
  ];

  public logisticsPaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public logisticsTotal = 0;

  public id = Number(this.$route.query.id);

  private needReload = false;

  /**
   * 当前门店明细可以展示的所有门店数据
   */
  private shopDataList: Array<DeliveryShopList> = [];

  private allDeliveryShopList: Array<DeliveryShopList> = [];

  public activated(): void {
    this.id = Number(this.$route.query.id);
    this.shopQueryForm = {};
    this.shopPaging.currentPage = 1;
    this.productPaging.currentPage = 1;
    this.logisticsPaging.currentPage = 1;

    this.reloadInfo();
  }

  public created(): void {
    if (!isNaN(this.id)) {
      this.initDeliveryNoticeInfo(this.id);
    }
  }

  public get getStatusName(): string {
    return translation(`deliveryNoticeStatus.${DeliveryStatusEnum[this.deliveryNotice!.status]}`);
  }

  public get allowComplete(): boolean {
    return this.deliveryNotice?.status === DeliveryStatusEnum.waitDelivery;
  }

  public getDeliveryMode(mode: DeliveryModeEnum): string {
    return `deliveryMode.${DeliveryModeEnum[mode]}`;
  }

  public shopClick(deliveryShop: DeliveryShopList): void {
    this.currentDeliveryProduct = deliveryShop.shopItemReList;
    this.currentDeliveryShopName = ` —— ${deliveryShop.shopName}`;
    this.productPagingData();
  }

  public shopQueryList(): void {
    this.shopPaging.currentPage = 1;
    let res: Array<DeliveryShopList> = cloneDeep(this.deliveryNotice?.shopList ?? []);

    if (this.shopQueryForm.keywords && this.shopQueryForm.keywords !== '') {
      res = res.filter(x => x.shopName.indexOf(this.shopQueryForm.keywords!) >= 0) ?? [];
    }
    if (this.shopQueryForm.isCreatedLogisticsOrder === 'yes') {
      res = res.filter(x => x.logisticsOrderTotal > 0);
    }
    if (this.shopQueryForm.isCreatedLogisticsOrder === 'no') {
      res = res.filter(x => !x.logisticsOrderTotal);
    }
    this.shopDataList = res;
    this.shopPagingData();
  }

  public shopPagingData(): void {
    this.shopTotal = this.shopDataList.length;
    this.shopTableOptions.data =
      this.shopDataList.slice(
        (this.shopPaging.currentPage - 1) * this.shopPaging.showCount,
        this.shopPaging.currentPage * this.shopPaging.showCount
      ) || [];

    if (this.shopTotal > 0) {
      this.shopClick(this.shopTableOptions.data[0] as any);
    }
  }

  public productPagingData(): void {
    this.productTotal = this.currentDeliveryProduct.length;
    this.productTableOptions.data = this.currentDeliveryProduct.slice(
      (this.productPaging.currentPage - 1) * this.productPaging.showCount,
      this.productPaging.currentPage * this.productPaging.showCount
    );
  }

  public logisticsPagingData(): void {
    this.logisticsTotal = this.deliveryNotice?.logisticsOrderVOList.length ?? 0;
    this.logisticsTableOptions.data =
      this.deliveryNotice?.logisticsOrderVOList.slice(
        (this.logisticsPaging.currentPage - 1) * this.logisticsPaging.showCount,
        this.logisticsPaging.currentPage * this.logisticsPaging.showCount
      ) ?? [];
  }

  public deliveryComplete(): void {
    deliveryNoticeService
      .deliveryComplete([this.deliveryNotice!.id])
      .then(() => {
        Message.success(translation('operationRes.operationSuccess'));
        this.needReload = true;
        this.$router.go(-1);
      })
      .catch(error => {
        messageError(error);
      });
  }

  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    if (to.path === '/delivery-notice') {
      to.meta!.needReload = this.needReload;
    }
    next();
  }

  public handleSelectionChange(rows: Array<DeliveryShopList>): void {
    this.selectedShops = rows;
  }

  public addedLogistics(): void {
    this.addLogisticsParams = null;
    this.reloadInfo();
  }

  public linkToLogisticsDetails(logistics: LogisticsList): void {
    this.$router.push({
      path: 'logistics-details',
      query: {
        id: logistics.id.toString()
      }
    });
  }

  /**
   * 获取物流单状态class
   * @param logistics 物流单
   * @returns
   */
  public getLogisticsStatusClass(logistics: LogisticsList): string {
    switch (logistics.status) {
      case LogisticsOrderStatusEnum.new:
        return 'info-dot';
      case LogisticsOrderStatusEnum.inTransit:
        return 'primary-dot';
      case LogisticsOrderStatusEnum.closed:
        return 'success-dot';
      default:
        return 'danger-dot';
    }
  }

  /**
   * 获取物流单状态名称
   * @param logistics 物流单
   * @returns
   */
  public getLogisticsStatusName(logistics: LogisticsList): string {
    if (!LogisticsOrderStatusEnum[logistics.status]) {
      return translation('common.unKnownStatus');
    }
    return `logisticsOrderStatus.${LogisticsOrderStatusEnum[logistics.status]}`;
  }

  public getLogisticsReceiverAdress(logistics: LogisticsList): string {
    return `${logistics.receivingProvince ?? ''}${logistics.receivingCity ??
      ''} ${logistics.receivingDistrict ?? ''}${logistics.receivingAddress ?? ''}`;
  }

  public getLogisticsSenderAdress(logistics: LogisticsList): string {
    return `${logistics.sendProvince ?? ''}${logistics.sendCity ?? ''}${logistics.sendDistrict ??
      ''}${logistics.senderAddress ?? ''}`;
  }

  public importLogistics(path: string): void {
    deliveryNoticeService
      .importLogistics(this.deliveryNotice!.id, path)
      .then(() => {
        this.reloadInfo();
        Message.success(translation('dialog.importSuccess'));
        this.importLogisticsVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  public showShipmentCard(): boolean {
    return !(this.deliveryNotice?.status === DeliveryStatusEnum.new);
  }

  private reloadInfo(): void {
    this.selectedShops = [];
    (this.$refs.shopTable as OsTable).clearSelection();
    this.initDeliveryNoticeInfo(Number(this.$route.query.id));
  }

  private initDeliveryNoticeInfo(id: number): void {
    deliveryNoticeService
      .getById(id)
      .then(res => {
        this.deliveryNotice = res;
        // 初始化门店明细数据
        this.shopDataList = cloneDeep(res.shopList);
        this.shopPagingData();
        this.logisticsPagingData();
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 导出海报类明细
   */
  private exportPosterDetails(): void {
    deliveryNoticeService
      .exportPoster([this.deliveryNotice!.id])
      .then((blob: any) => {
        downloadFileByBlob(
          `${translation('deliveryDetails.posterTemplateName')}_${dateFormat(new Date())}.xlsx`,
          blob
        );
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 导出Pop类明细
   */
  private exportPopDetails(): void {
    deliveryNoticeService
      .exportPop(this.deliveryNotice!.id)
      .then((blob: any) => {
        downloadFileByBlob(
          `${translation('deliveryDetails.popTemplateName')}_${dateFormat(new Date())}.xlsx`,
          blob
        );
      })
      .catch(error => {
        messageError(error);
      });
  }

  private openAddLogisticDialog(): void {
    this.addLogisticsParams = {
      projectId: this.deliveryNotice!.projectId,
      customerName: this.deliveryNotice!.customerName,
      customerId: this.deliveryNotice!.customerId,
      deliveryNoticeCode: this.deliveryNotice!.code,
      deliveryNoticeId: this.deliveryNotice!.id,
      requiredArriveTime: this.deliveryNotice!.requiredArriveTime,
      deliveryShopIdList: this.selectedShops.map(x => x.id),
      sendMethod: this.selectedShops.length === 1 ? this.selectedShops[0].sendMethod : undefined
    };
    this.addLogisticsVisible = true;
  }

  @Watch('selectedShops')
  private handleSelectedChanged(shops: Array<DeliveryShopList>): void {
    this.shopOperationOptions.forEach(x => {
      x.disabled = shops.length === 0;
    });
  }

  private allowAddLogistics(): boolean {
    const disabledAddStatus = [DeliveryStatusEnum.new, DeliveryStatusEnum.waitTakeOrder, DeliveryStatusEnum.complete];
    return disabledAddStatus.includes(this.deliveryNotice!.status);
  }

  private openImportLogisticsDialog(): void {
    this.importLogisticsVisible = true;
  }
}
